import _ from 'underscore';
import Select from 'react-select';
import moment from 'moment';

// Adapted from Zoom's allowed timezones:
// https://marketplace.zoom.us/docs/api-reference/other-references/abbreviation-lists#timezones
const zoneIdToName = {
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Pago_Pago': 'Pago Pago',
  'Pacific/Honolulu': 'Hawaii',
  'America/Anchorage': 'Alaska',
  'America/Vancouver': 'Vancouver',
  'America/Los_Angeles': 'Pacific Time (US and Canada)',
  'America/Tijuana': 'Tijuana',
  'America/Edmonton': 'Edmonton',
  'America/Denver': 'Mountain Time (US and Canada)',
  'America/Phoenix': 'Arizona',
  'America/Mazatlan': 'Mazatlan',
  'America/Winnipeg': 'Winnipeg',
  'America/Regina': 'Saskatchewan',
  'America/Chicago': 'Central Time (US and Canada)',
  'America/Mexico_City': 'Mexico City',
  'America/Guatemala': 'Guatemala',
  'America/El_Salvador': 'El Salvador',
  'America/Managua': 'Managua',
  'America/Costa_Rica': 'Costa Rica',
  'America/Montreal': 'Montreal',
  'America/New_York': 'Eastern Time (US and Canada)',
  'America/Indianapolis': 'Indiana (East)',
  'America/Panama': 'Panama',
  'America/Bogota': 'Bogota',
  'America/Lima': 'Lima',
  'America/Halifax': 'Halifax',
  'America/Puerto_Rico': 'Puerto Rico',
  'America/Caracas': 'Caracas',
  'America/Santiago': 'Santiago',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Montevideo': 'Montevideo',
  'America/Araguaina': 'Brasilia',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Greenland',
  'America/Sao_Paulo': 'Sao Paulo',
  'Atlantic/Azores': 'Azores',
  'Canada/Atlantic': 'Atlantic Time (Canada)',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  UTC: 'Universal Time UTC',
  'Etc/Greenwich': 'Greenwich Mean Time',
  'Europe/Belgrade': 'Belgrade, Bratislava, Ljubljana',
  CET: 'Sarajevo, Skopje, Zagreb',
  'Atlantic/Reykjavik': 'Reykjavik',
  'Europe/Dublin': 'Dublin',
  'Europe/London': 'London',
  'Europe/Lisbon': 'Lisbon',
  'Africa/Casablanca': 'Casablanca',
  'Africa/Nouakchott': 'Nouakchott',
  'Europe/Oslo': 'Oslo',
  'Europe/Copenhagen': 'Copenhagen',
  'Europe/Brussels': 'Brussels',
  'Europe/Berlin': 'Amsterdam, Berlin, Rome, Stockholm, Vienna',
  'Europe/Helsinki': 'Helsinki',
  'Europe/Amsterdam': 'Amsterdam',
  'Europe/Rome': 'Rome',
  'Europe/Stockholm': 'Stockholm',
  'Europe/Vienna': 'Vienna',
  'Europe/Luxembourg': 'Luxembourg',
  'Europe/Paris': 'Paris',
  'Europe/Zurich': 'Zurich',
  'Europe/Madrid': 'Madrid',
  'Africa/Bangui': 'West Central Africa',
  'Africa/Algiers': 'Algiers',
  'Africa/Tunis': 'Tunis',
  'Africa/Harare': 'Harare, Pretoria',
  'Africa/Nairobi': 'Nairobi',
  'Europe/Warsaw': 'Warsaw',
  'Europe/Prague': 'Prague Bratislava',
  'Europe/Budapest': 'Budapest',
  'Europe/Sofia': 'Sofia',
  'Europe/Istanbul': 'Istanbul',
  'Europe/Athens': 'Athens',
  'Europe/Bucharest': 'Bucharest',
  'Asia/Nicosia': 'Nicosia',
  'Asia/Beirut': 'Beirut',
  'Asia/Damascus': 'Damascus',
  'Asia/Jerusalem': 'Jerusalem',
  'Asia/Amman': 'Amman',
  'Africa/Tripoli': 'Tripoli',
  'Africa/Cairo': 'Cairo',
  'Africa/Johannesburg': 'Johannesburg',
  'Europe/Moscow': 'Moscow',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Kuwait': 'Kuwait',
  'Asia/Riyadh': 'Riyadh',
  'Asia/Bahrain': 'Bahrain',
  'Asia/Qatar': 'Qatar',
  'Asia/Aden': 'Aden',
  'Asia/Tehran': 'Tehran',
  'Africa/Khartoum': 'Khartoum',
  'Africa/Djibouti': 'Djibouti',
  'Africa/Mogadishu': 'Mogadishu',
  'Asia/Dubai': 'Dubai',
  'Asia/Muscat': 'Muscat',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Yekaterinburg',
  'Asia/Tashkent': 'Islamabad, Karachi, Tashkent',
  'Asia/Calcutta': 'India',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Novosibirsk': 'Novosibirsk',
  'Asia/Almaty': 'Almaty',
  'Asia/Dacca': 'Dacca',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Bangkok': 'Bangkok',
  'Asia/Saigon': 'Vietnam',
  'Asia/Jakarta': 'Jakarta',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Shanghai': 'Beijing, Shanghai',
  'Asia/Hong_Kong': 'Hong Kong',
  'Asia/Taipei': 'Taipei',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur',
  'Asia/Singapore': 'Singapore',
  'Australia/Perth': 'Perth',
  'Asia/Yakutsk': 'Yakutsk',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Port_Moresby': 'Guam, Port Moresby',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Australia/Hobart': 'Hobart',
  'Asia/Magadan': 'Magadan',
  'Pacific/Guadalcanal': 'Solomon Islands',
  'Pacific/Noumea': 'New Caledonia',
  'Asia/Kamchatka': 'Kamchatka',
  'Pacific/Fiji': 'Fiji Islands, Marshall Islands',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Asia/Kolkata': 'Mumbai, Kolkata, New Delhi',
  'Europe/Kiev': 'Kiev',
  'America/Tegucigalpa': 'Tegucigalpa',
  'Pacific/Apia': 'Independent State of Samoa',
};

const nonMomentAbbreviations = {
  'America/Bogota': 'COT',
  'America/Lima': 'PET',
  'America/Caracas': 'VET',
  'America/Santiago': 'CLST',
  'America/Montevideo': 'UYT',
  'America/Araguaina': 'BRT',
  'America/Argentina/Buenos_Aires': 'ART',
  'America/Godthab': 'GNST',
  'America/Sao_Paulo': 'BRT',
  'Atlantic/Azores': 'AZOT',
  'Atlantic/Cape_Verde': 'CVT',
  'Europe/Istanbul': 'TRT',
  'Asia/Baghdad': 'AST',
  'Asia/Kuwait': 'AST',
  'Asia/Riyadh': 'AST',
  'Asia/Bahrain': 'AST',
  'Asia/Qatar': 'AST',
  'Asia/Aden': 'AST',
  'Asia/Tehran': 'IRDT',
  'Asia/Dubai': 'GST',
  'Asia/Muscat': 'GST',
  'Asia/Baku': 'AZT',
  'Asia/Kabul': 'AFT',
  'Asia/Yekaterinburg': 'YEKT',
  'Asia/Tashkent': 'UZT',
  'Asia/Kathmandu': 'NPT',
  'Asia/Novosibirsk': 'NOVT',
  'Asia/Almaty': 'ALMT',
  'Asia/Dacca': 'BST', // The Asia/Dacca time zone has been deemed obsolete. It has been replaced by Asia/Dhaka.
  'Asia/Krasnoyarsk': 'KRAT',
  'Asia/Dhaka': 'BST',
  'Asia/Bangkok': 'ICT',
  'Asia/Saigon': 'IDT', // Same as Asia/Ho_Chi_Minh
  'Asia/Irkutsk': 'IRKT',
  'Asia/Kuala_Lumpur': 'MYT',
  'Asia/Singapore': 'SST',
  'Asia/Yakutsk': 'YAKT',
  'Asia/Vladivostok': 'VLAT',
  'Pacific/Port_Moresby': 'PGT',
  'Asia/Magadan': 'MAGT',
  'Pacific/Guadalcanal': 'SBT',
  'Pacific/Noumea': 'NCT',
  'Asia/Kamchatka': 'PETT',
  'Pacific/Fiji': 'FJT',
  'Pacific/Apia': 'WST',
};

const browserZoneId = moment.tz.guess();

const selectOptionData = (zoneId) => {
  const tzMoment = moment.tz(zoneId);
  const name = zoneIdToName[zoneId];
  const labelName = name || zoneId;

  return {
    value: zoneId,
    label: `(GMT${tzMoment.format('Z')}) ${labelName}`,
    utcOffset: tzMoment.utcOffset(),
    name: labelName,
  };
};

const convertMomentToLocalTime = (momentObj) => moment(momentObj).tz(moment.tz.guess(), true);

const convertMomentToTzTime = (momentObj, tzId) => moment(momentObj).tz(tzId, true);

export const selectOptions = (currentTimezoneId) =>
  _.chain(zoneIdToName)
    .map((__, zoneId) => selectOptionData(zoneId))
    .sortBy((data) => {
      if (data.value === currentTimezoneId) return -1000;
      if (!currentTimezoneId && data.value === browserZoneId) return -1000;

      return data.utcOffset;
    })
    .value();

const tzAbbreviation = (zoneId) => nonMomentAbbreviations[zoneId] || moment.tz(zoneId).zoneAbbr();

const TimezonePicker = (props) => <Select {...props} options={selectOptions(props.currentTimezoneId)} />;

export {
  TimezonePicker,
  selectOptionData,
  tzAbbreviation,
  convertMomentToLocalTime,
  convertMomentToTzTime,
  zoneIdToName,
};

/**
 * A more exhaustive list of zone ids. May need to map these somehow to zoneIdToName to ensure tz.guess() gets correct data
 // const zones = [
 //   'Africa/Abidjan',
 //   'Africa/Accra',
 //   'Africa/Algiers',
 //   'Africa/Bissau',
 //   'Africa/Cairo',
 //   'Africa/Casablanca',
 //   'Africa/Ceuta',
 //   'Africa/El_Aaiun',
 //   'Africa/Johannesburg',
 //   'Africa/Juba',
 //   'Africa/Khartoum',
 //   'Africa/Lagos',
 //   'Africa/Maputo',
 //   'Africa/Monrovia',
 //   'Africa/Nairobi',
 //   'Africa/Ndjamena',
 //   'Africa/Tripoli',
 //   'Africa/Tunis',
 //   'Africa/Windhoek',
 //   'America/Adak',
 //   'America/Anchorage',
 //   'America/Araguaina',
 //   'America/Argentina/Buenos_Aires',
 //   'America/Argentina/Catamarca',
 //   'America/Argentina/Cordoba',
 //   'America/Argentina/Jujuy',
 //   'America/Argentina/La_Rioja',
 //   'America/Argentina/Mendoza',
 //   'America/Argentina/Rio_Gallegos',
 //   'America/Argentina/Salta',
 //   'America/Argentina/San_Juan',
 //   'America/Argentina/San_Luis',
 //   'America/Argentina/Tucuman',
 //   'America/Argentina/Ushuaia',
 //   'America/Asuncion',
 //   'America/Atikokan',
 //   'America/Bahia',
 //   'America/Bahia_Banderas',
 //   'America/Barbados',
 //   'America/Belem',
 //   'America/Belize',
 //   'America/Blanc-Sablon',
 //   'America/Boa_Vista',
 //   'America/Bogota',
 //   'America/Boise',
 //   'America/Cambridge_Bay',
 //   'America/Campo_Grande',
 //   'America/Cancun',
 //   'America/Caracas',
 //   'America/Cayenne',
 //   'America/Chicago',
 //   'America/Chihuahua',
 //   'America/Costa_Rica',
 //   'America/Creston',
 //   'America/Cuiaba',
 //   'America/Curacao',
 //   'America/Danmarkshavn',
 //   'America/Dawson',
 //   'America/Dawson_Creek',
 //   'America/Denver',
 //   'America/Detroit',
 //   'America/Edmonton',
 //   'America/Eirunepe',
 //   'America/El_Salvador',
 //   'America/Fort_Nelson',
 //   'America/Fortaleza',
 //   'America/Glace_Bay',
 //   'America/Godthab',
 //   'America/Goose_Bay',
 //   'America/Grand_Turk',
 //   'America/Guatemala',
 //   'America/Guayaquil',
 //   'America/Guyana',
 //   'America/Halifax',
 //   'America/Havana',
 //   'America/Hermosillo',
 //   'America/Indiana/Indianapolis',
 //   'America/Indiana/Knox',
 //   'America/Indiana/Marengo',
 //   'America/Indiana/Petersburg',
 //   'America/Indiana/Tell_City',
 //   'America/Indiana/Vevay',
 //   'America/Indiana/Vincennes',
 //   'America/Indiana/Winamac',
 //   'America/Inuvik',
 //   'America/Iqaluit',
 //   'America/Jamaica',
 //   'America/Juneau',
 //   'America/Kentucky/Louisville',
 //   'America/Kentucky/Monticello',
 //   'America/La_Paz',
 //   'America/Lima',
 //   'America/Los_Angeles',
 //   'America/Maceio',
 //   'America/Managua',
 //   'America/Manaus',
 //   'America/Martinique',
 //   'America/Matamoros',
 //   'America/Mazatlan',
 //   'America/Menominee',
 //   'America/Merida',
 //   'America/Metlakatla',
 //   'America/Mexico_City',
 //   'America/Miquelon',
 //   'America/Moncton',
 //   'America/Monterrey',
 //   'America/Montevideo',
 //   'America/Nassau',
 //   'America/New_York',
 //   'America/Nipigon',
 //   'America/Nome',
 //   'America/Noronha',
 //   'America/North_Dakota/Beulah',
 //   'America/North_Dakota/Center',
 //   'America/North_Dakota/New_Salem',
 //   'America/Ojinaga',
 //   'America/Panama',
 //   'America/Pangnirtung',
 //   'America/Paramaribo',
 //   'America/Phoenix',
 //   'America/Port_of_Spain',
 //   'America/Port-au-Prince',
 //   'America/Porto_Velho',
 //   'America/Puerto_Rico',
 //   'America/Punta_Arenas',
 //   'America/Rainy_River',
 //   'America/Rankin_Inlet',
 //   'America/Recife',
 //   'America/Regina',
 //   'America/Resolute',
 //   'America/Rio_Branco',
 //   'America/Santarem',
 //   'America/Santiago',
 //   'America/Santo_Domingo',
 //   'America/Sao_Paulo',
 //   'America/Scoresbysund',
 //   'America/Sitka',
 //   'America/St_Johns',
 //   'America/Swift_Current',
 //   'America/Tegucigalpa',
 //   'America/Thule',
 //   'America/Thunder_Bay',
 //   'America/Tijuana',
 //   'America/Toronto',
 //   'America/Vancouver',
 //   'America/Whitehorse',
 //   'America/Winnipeg',
 //   'America/Yakutat',
 //   'America/Yellowknife',
 //   'Antarctica/Casey',
 //   'Antarctica/Davis',
 //   'Antarctica/DumontDUrville',
 //   'Antarctica/Macquarie',
 //   'Antarctica/Mawson',
 //   'Antarctica/Palmer',
 //   'Antarctica/Rothera',
 //   'Antarctica/Syowa',
 //   'Antarctica/Troll',
 //   'Antarctica/Vostok',
 //   'Asia/Almaty',
 //   'Asia/Amman',
 //   'Asia/Anadyr',
 //   'Asia/Aqtau',
 //   'Asia/Aqtobe',
 //   'Asia/Ashgabat',
 //   'Asia/Atyrau',
 //   'Asia/Baghdad',
 //   'Asia/Baku',
 //   'Asia/Bangkok',
 //   'Asia/Barnaul',
 //   'Asia/Beirut',
 //   'Asia/Bishkek',
 //   'Asia/Brunei',
 //   'Asia/Chita',
 //   'Asia/Choibalsan',
 //   'Asia/Colombo',
 //   'Asia/Damascus',
 //   'Asia/Dhaka',
 //   'Asia/Dili',
 //   'Asia/Dubai',
 //   'Asia/Dushanbe',
 //   'Asia/Famagusta',
 //   'Asia/Gaza',
 //   'Asia/Hebron',
 //   'Asia/Ho_Chi_Minh',
 //   'Asia/Hong_Kong',
 //   'Asia/Hovd',
 //   'Asia/Irkutsk',
 //   'Asia/Jakarta',
 //   'Asia/Jayapura',
 //   'Asia/Jerusalem',
 //   'Asia/Kabul',
 //   'Asia/Kamchatka',
 //   'Asia/Karachi',
 //   'Asia/Kathmandu',
 //   'Asia/Khandyga',
 //   'Asia/Kolkata',
 //   'Asia/Krasnoyarsk',
 //   'Asia/Kuala_Lumpur',
 //   'Asia/Kuching',
 //   'Asia/Macau',
 //   'Asia/Magadan',
 //   'Asia/Makassar',
 //   'Asia/Manila',
 //   'Asia/Novokuznetsk',
 //   'Asia/Novosibirsk',
 //   'Asia/Omsk',
 //   'Asia/Oral',
 //   'Asia/Pontianak',
 //   'Asia/Pyongyang',
 //   'Asia/Qatar',
 //   'Asia/Qyzylorda',
 //   'Asia/Riyadh',
 //   'Asia/Sakhalin',
 //   'Asia/Samarkand',
 //   'Asia/Seoul',
 //   'Asia/Shanghai',
 //   'Asia/Singapore',
 //   'Asia/Srednekolymsk',
 //   'Asia/Taipei',
 //   'Asia/Tashkent',
 //   'Asia/Tbilisi',
 //   'Asia/Tehran',
 //   'Asia/Thimphu',
 //   'Asia/Tokyo',
 //   'Asia/Tomsk',
 //   'Asia/Ulaanbaatar',
 //   'Asia/Urumqi',
 //   'Asia/Ust-Nera',
 //   'Asia/Vladivostok',
 //   'Asia/Yakutsk',
 //   'Asia/Yangon',
 //   'Asia/Yekaterinburg',
 //   'Asia/Yerevan',
 //   'Atlantic/Azores',
 //   'Atlantic/Bermuda',
 //   'Atlantic/Canary',
 //   'Atlantic/Cape_Verde',
 //   'Atlantic/Faroe',
 //   'Atlantic/Madeira',
 //   'Atlantic/Reykjavik',
 //   'Atlantic/South_Georgia',
 //   'Atlantic/Stanley',
 //   'Australia/Adelaide',
 //   'Australia/Brisbane',
 //   'Australia/Broken_Hill',
 //   'Australia/Currie',
 //   'Australia/Darwin',
 //   'Australia/Eucla',
 //   'Australia/Hobart',
 //   'Australia/Lindeman',
 //   'Australia/Lord_Howe',
 //   'Australia/Melbourne',
 //   'Australia/Perth',
 //   'Australia/Sydney',
 //   'Etc/GMT',
 //   'Etc/GMT+1',
 //   'Etc/GMT+10',
 //   'Etc/GMT+11',
 //   'Etc/GMT+12',
 //   'Etc/GMT+2',
 //   'Etc/GMT+3',
 //   'Etc/GMT+4',
 //   'Etc/GMT+5',
 //   'Etc/GMT+6',
 //   'Etc/GMT+7',
 //   'Etc/GMT+8',
 //   'Etc/GMT+9',
 //   'Etc/GMT-1',
 //   'Etc/GMT-10',
 //   'Etc/GMT-11',
 //   'Etc/GMT-12',
 //   'Etc/GMT-13',
 //   'Etc/GMT-14',
 //   'Etc/GMT-2',
 //   'Etc/GMT-3',
 //   'Etc/GMT-4',
 //   'Etc/GMT-5',
 //   'Etc/GMT-6',
 //   'Etc/GMT-7',
 //   'Etc/GMT-8',
 //   'Etc/GMT-9',
 //   'Etc/UTC',
 //   'Europe/Amsterdam',
 //   'Europe/Andorra',
 //   'Europe/Astrakhan',
 //   'Europe/Athens',
 //   'Europe/Belgrade',
 //   'Europe/Berlin',
 //   'Europe/Brussels',
 //   'Europe/Bucharest',
 //   'Europe/Budapest',
 //   'Europe/Chisinau',
 //   'Europe/Copenhagen',
 //   'Europe/Dublin',
 //   'Europe/Gibraltar',
 //   'Europe/Helsinki',
 //   'Europe/Istanbul',
 //   'Europe/Kaliningrad',
 //   'Europe/Kiev',
 //   'Europe/Kirov',
 //   'Europe/Lisbon',
 //   'Europe/London',
 //   'Europe/Luxembourg',
 //   'Europe/Madrid',
 //   'Europe/Malta',
 //   'Europe/Minsk',
 //   'Europe/Monaco',
 //   'Europe/Moscow',
 //   'Europe/Nicosia',
 //   'Europe/Oslo',
 //   'Europe/Paris',
 //   'Europe/Prague',
 //   'Europe/Riga',
 //   'Europe/Rome',
 //   'Europe/Samara',
 //   'Europe/Saratov',
 //   'Europe/Simferopol',
 //   'Europe/Sofia',
 //   'Europe/Stockholm',
 //   'Europe/Tallinn',
 //   'Europe/Tirane',
 //   'Europe/Ulyanovsk',
 //   'Europe/Uzhgorod',
 //   'Europe/Vienna',
 //   'Europe/Vilnius',
 //   'Europe/Volgograd',
 //   'Europe/Warsaw',
 //   'Europe/Zaporozhye',
 //   'Europe/Zurich',
 //   'Indian/Chagos',
 //   'Indian/Christmas',
 //   'Indian/Cocos',
 //   'Indian/Kerguelen',
 //   'Indian/Mahe',
 //   'Indian/Maldives',
 //   'Indian/Mauritius',
 //   'Indian/Reunion',
 //   'Pacific/Apia',
 //   'Pacific/Auckland',
 //   'Pacific/Bougainville',
 //   'Pacific/Chatham',
 //   'Pacific/Chuuk',
 //   'Pacific/Easter',
 //   'Pacific/Efate',
 //   'Pacific/Enderbury',
 //   'Pacific/Fakaofo',
 //   'Pacific/Fiji',
 //   'Pacific/Funafuti',
 //   'Pacific/Galapagos',
 //   'Pacific/Gambier',
 //   'Pacific/Guadalcanal',
 //   'Pacific/Guam',
 //   'Pacific/Honolulu',
 //   'Pacific/Kiritimati',
 //   'Pacific/Kosrae',
 //   'Pacific/Kwajalein',
 //   'Pacific/Majuro',
 //   'Pacific/Marquesas',
 //   'Pacific/Nauru',
 //   'Pacific/Niue',
 //   'Pacific/Norfolk',
 //   'Pacific/Noumea',
 //   'Pacific/Pago_Pago',
 //   'Pacific/Palau',
 //   'Pacific/Pitcairn',
 //   'Pacific/Pohnpei',
 //   'Pacific/Port_Moresby',
 //   'Pacific/Rarotonga',
 //   'Pacific/Tahiti',
 //   'Pacific/Tarawa',
 //   'Pacific/Tongatapu',
 //   'Pacific/Wake',
 //   'Pacific/Wallis',
 // ];
 * */
